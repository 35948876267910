export const noEmptyRules = [(v: string) => !!v || "入力してください"];

export const yearRules = (
  t: (key: string) => string
): Array<(v: string) => boolean | string> => {
  const currentYear = new Date().getFullYear();
  const requiredRule = (v: string) =>
    !!v || t("app.appointmentSchedule.pleaseInput");

  const formatRule = (v: string) =>
    !v || /^\d{4}$/.test(v) || t("form.dateField.invalidYear");

  const birthDateRangeRule = (v: string) =>
    !v ||
    (parseInt(v) <= currentYear && parseInt(v) > currentYear - 100) ||
    t("form.dateField.invalidYear");

  return [requiredRule, formatRule, birthDateRangeRule];
};

export const monthRules = (
  t: (key: string) => string
): Array<(v: string) => boolean | string> => {
  const requiredRule = (v: string) =>
    !!v || t("app.appointmentSchedule.pleaseInput");

  return [requiredRule];
};

export const dayRules = (
  t: (key: string) => string
): Array<(v: string) => boolean | string> => {
  const requiredRule = (v: string) =>
    !!v || t("app.appointmentSchedule.pleaseInput");

  const dayValidationRule = (v: string) =>
    !v ||
    (/^\d{1,2}$/.test(v) &&
      parseInt(v) >= 1 &&
      parseInt(v) <= 31) ||
    t("form.dateField.invalidDate");

  return [requiredRule, dayValidationRule];
};
